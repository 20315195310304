<template>
  <div
    class="carousel-items-container align-items-center"
    @mouseenter="stopAutoPlay"
    @mouseleave="startAutoPlay"
  >
    <button
      v-if="showNavButtons"
      class="nav-button prev bg-white text-tertiary"
      @click="slide('prev')"
    >
      <Icon :type="iconType" name="chevron-left" :size="iconSize"/>
    </button>

    <div class="carousel-items py-3 w-100 overflow-hidden">
      <div
        class="items-grid d-flex"
        :class="{ 'cursor-grab': draggable, 'cursor-grabbing': isDragging }"
        :style="gridOffset"
        ref="sliderRef"
      >
        <div
          @mousedown="startDrag"
          @mousemove="onDrag"
          @mouseup="endDrag"
          @mouseleave="endDrag"
          @touchstart="startDrag"
          @touchmove="onDrag"
          @touchend="endDrag"
          v-for="item in displayedItems"
          :key="itemKey ? item[itemKey] : item"
          class="item-slot"
        >
          <slot name="item" :item="item"></slot>
        </div>
      </div>
    </div>

    <button
      v-if="showNavButtons"
      class="nav-button next bg-white text-tertiary"
      @click="slide('next')"
    >
      <Icon :type="iconType" name="chevron-right" :size="iconSize"/>
    </button>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, computed, watch } from 'vue';

const emit = defineEmits(["lastItemReached"]);

const props = withDefaults(defineProps<{
  items: any[];
  itemKey?: string;
  autoPlayDelay?: number;
  itemsPerView?: number;
  autoPlay?: boolean;
  draggable?: boolean;
  iconType?: string;
  iconSize?: string;
  showNavButtons?: boolean;
  infiniteScroll?: boolean;
}>(), {
  itemsPerView: 4,
  autoPlay: false,
  draggable: false,
  iconType: 'fas',
  iconSize: 'xl',
  showNavButtons: true,
  infiniteScroll: true
});

const sliderRef        = ref<HTMLElement | null>(null);
const itemWidth        = computed(() => (100 / props.itemsPerView));
const position         = ref(0);
const displayedItems   = ref([...props.items]);
const isDragging       = ref(false);
const dragStart        = ref({ x: 0, position: 0 });
const currentTranslate = ref(0);
const currentIndex     = ref(0);

// Cache computed values for better performance
const maxDragPosition = computed(() => 0);
const minDragPosition = computed(() => -(displayedItems.value.length - props.itemsPerView) * itemWidth.value);
const maxIndex        = computed(() => displayedItems.value.length - props.itemsPerView);

// Auto-play configuration
const AUTO_PLAY_DELAY     = props.autoPlayDelay || 3000;
const TRANSITION_DURATION = 300;
let autoPlayTimer: number | null = null;

// This is used to offset the grid to the left when there are more items than the number of items per view,
// so that there are no empty slots on the left side of the carousel. This allows to drag the carousel to the left (homepage media partners)
const gridOffset = computed(() => {
  if (!props.infiniteScroll || displayedItems.value.length <= props.itemsPerView + 1) return {};
  const offset = Math.min(displayedItems.value.length - props.itemsPerView - 1, 3);
  return offset > 0 ? { left: `${-itemWidth.value * offset}%` } : {};
});

// Watch for props.items changes
watch(() => props.items, (newItems) => {
  displayedItems.value = [...newItems];
}, { immediate: true });

function animateSlider(targetPosition: number, transition = true) {
  if (!sliderRef.value) return;
  sliderRef.value.style.transition = transition ? `transform ${TRANSITION_DURATION}ms ease` : 'none';
  sliderRef.value.style.transform = `translateX(${targetPosition}%)`;
}

function slide(direction: 'next' | 'prev') {
  if (direction === 'prev') {
    if (props.infiniteScroll) {
      displayedItems.value.unshift(displayedItems.value.pop()!);
    }
    position.value = -itemWidth.value; // Start from moved position
    animateSlider(-itemWidth.value, false); // Instantly move to start position

    requestAnimationFrame(() => {
      position.value = 0;
      animateSlider(0, true);
    });
  } else {
    if (!props.infiniteScroll && currentIndex.value >= maxIndex.value) {
      emit('lastItemReached');
      return;
    }

    const targetPosition = -itemWidth.value;
    position.value = targetPosition;
    animateSlider(targetPosition, true);

    setTimeout(() => {
      if (props.infiniteScroll) {
        const firstItem = displayedItems.value.shift();
        if (firstItem) displayedItems.value.push(firstItem);
      } else {
        currentIndex.value = Math.min(maxIndex.value, currentIndex.value + 1);
        if (currentIndex.value >= maxIndex.value) {
          emit('lastItemReached');
        }
      }
      position.value = 0;
      animateSlider(0, false);
    }, TRANSITION_DURATION);
  }
}

function getPositionX(event: MouseEvent | TouchEvent): number {
  return event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
}

function startDrag(event: MouseEvent | TouchEvent) {
  if (!props.draggable) return;
  stopAutoPlay();
  isDragging.value = true;
  dragStart.value = {
    x: getPositionX(event),
    position: position.value
  };
  currentTranslate.value = position.value;
  if (sliderRef.value) {
    sliderRef.value.style.transition = 'none';
  }
}

function onDrag(event: MouseEvent | TouchEvent) {
  if (!isDragging.value) return;
  event.preventDefault();
  const currentX = getPositionX(event);
  const diff     = currentX - dragStart.value.x;
  const drag     = (diff / sliderRef.value?.offsetWidth!) * 100;

  // Calculate the new position
  let newPosition = dragStart.value.position + drag;

  // If not infinite scroll, limit the drag range with bounce effect
  if (!props.infiniteScroll) {
    const dragPastMax = newPosition - maxDragPosition.value;
    const dragPastMin = newPosition - minDragPosition.value;

    if (dragPastMax > 0) {
      // Bounce effect when trying to drag right past first item
      newPosition = maxDragPosition.value + (dragPastMax * 0.1);
      if (!sliderRef.value?.classList.contains('bounce-right')) {
        sliderRef.value?.classList.add('bounce-right');
      }
    } else if (dragPastMin < 0) {
      // Bounce effect when trying to drag left past last item
      newPosition = minDragPosition.value + (dragPastMin * 0.1);
      if (!sliderRef.value?.classList.contains('bounce-left')) {
        sliderRef.value?.classList.add('bounce-left');
      }
    } else {
      // Normal drag within bounds
      sliderRef.value?.classList.remove('bounce-right', 'bounce-left');
    }
  }

  currentTranslate.value = newPosition;
  animateSlider(newPosition, false);
}

function endDrag() {
  if (!isDragging.value) return;

  // Remove bounce classes when ending drag
  sliderRef.value?.classList.remove('bounce-right', 'bounce-left');

  const dragDistance = currentTranslate.value - dragStart.value.position;
  const itemsToMove = Math.round(dragDistance / itemWidth.value);

  if (Math.abs(itemsToMove) > 0) {
    let targetPosition = dragStart.value.position + (itemsToMove * itemWidth.value);

    if (!props.infiniteScroll) {
      targetPosition = Math.min(maxDragPosition.value, Math.max(minDragPosition.value, targetPosition));
    }

    position.value = targetPosition;
    animateSlider(targetPosition, true);

    if (props.infiniteScroll) {
      setTimeout(() => {
        for (let i = 0; i < Math.abs(itemsToMove); i++) {
          if (itemsToMove > 0) {
            const lastItem = displayedItems.value.pop();
            if (lastItem) displayedItems.value.unshift(lastItem);
          } else {
            const [firstItem] = displayedItems.value.splice(0, 1);
            displayedItems.value.push(firstItem);
          }
        }
        position.value = 0;
        animateSlider(0, false);
      }, TRANSITION_DURATION);
    } else {
      const lastVisibleIndex = Math.abs(Math.round(targetPosition / itemWidth.value));
      currentIndex.value = Math.min(maxIndex.value, lastVisibleIndex);

      if (currentIndex.value >= maxIndex.value) {
        emit('lastItemReached');
      }
    }
  } else {
    // Snap back to original position if drag wasn't far enough
    animateSlider(dragStart.value.position);
    position.value = dragStart.value.position;
  }

  isDragging.value = false;
  startAutoPlay();
}

function startAutoPlay() {
  if (!props.autoPlay) return;
  stopAutoPlay();
  autoPlayTimer = window.setInterval(() => {
    if (!isDragging.value) {
      slide('next');
    }
  }, AUTO_PLAY_DELAY);
}

function stopAutoPlay() {
  if (!props.autoPlay) return;
  if (autoPlayTimer) {
    clearInterval(autoPlayTimer);
    autoPlayTimer = null;
  }
}

onMounted(() => {
  startAutoPlay();
});

onUnmounted(() => {
  stopAutoPlay();
});
</script>

<style scoped>
.carousel-items-container {
  position: relative;
  .carousel-items {
    .items-grid {
      will-change: transform;
      user-select: none;
      touch-action: pan-y;
      position: relative;
      transition: transform 0.3s ease;
    }
  }

  .carousel-items {
    .cursor-grab {
      cursor: grab;
    }

    .cursor-grabbing {
      cursor: grabbing;
    }
  }

  .item-slot {
    flex: 0 0 v-bind(itemWidth + '%');

    img {
      object-fit: contain;
      pointer-events: none;
      user-select: none;
      max-width: 100%;
      height: auto;
    }
  }
}

.bounce-right {
  animation: bounceRight 0.3s ease forwards;
}

.bounce-left {
  animation: bounceLeft 0.3s ease forwards;
}

@keyframes bounceRight {
  0% { transform: translateX(0%); }
  50% { transform: translateX(2%); }
  100% { transform: translateX(0%); }
}

@keyframes bounceLeft {
  0% { transform: translateX(v-bind(minDragPosition + '%')); }
  50% { transform: translateX(calc(v-bind(minDragPosition + '%') - 2%)); }
  100% { transform: translateX(v-bind(minDragPosition + '%')); }
}
</style>